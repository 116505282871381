/**
 * environment variable.
 * @description Global app environment variables stored in the object.
 */
const environment = {
  api: {
    base: process.env['base-api'],
  },
  port: process.env.port,
  mode: process.env.REACT_APP_ENV,
  config: process.env.config,
  width: 1441,
  reportBase: process.env['report-base'],
  investorBase: process.env['investor-base'],

  http: {
    report: process.env['report-base'],
    partner: process.env['partner-base'],
    authFrontend: process.env['auth-base'],
  },
  recaptcha: {
    siteKey: process.env['recaptcha-sitekey'],
    siteKeyV2: process.env['recaptcha-sitekeyv2'],
  },

  imgPath: process.env['img-path'],

  portals: {
    client: process.env['client-base'],
    management: process.env['management-base'],
    authFrontend: process.env['auth-base'],
  },
};

export { environment };
