import '../../../styles/global.scss';

import {
  GetSupportInfoResponse,
  PaymentFormat,
  UserRole,
  changeUserRole,
  getP4,
  getSupportInfo,
  getUnreadMessagesCount,
} from '../../../services/api';
import { Header, NoDesktop, Preloader, Sidebar } from './components';
import { InformationRouter, ReportsRouter } from './pages';
import { Navigate, Outlet, Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import React, { FC, Suspense, lazy, useCallback, useEffect, useMemo, useState } from 'react';
import { useAppContext, useModalsContext } from './contexts';

import { ReactComponent as ActivityIcon } from '../assets/icons/notes.svg';
import { ReactComponent as ClipboardIcon } from '../assets/icons/clipboard-notes.svg';
import { ReactComponent as ClockIcon } from '../../../assets/icons/clock-seven.svg';
import { ReactComponent as DollarIcon } from '../assets/icons/dollar-sign.svg';
import { ReactComponent as EnvelopeIcon } from '../assets/icons/envelope-alt.svg';
import { ReactComponent as HeadphonesIcon } from '../assets/icons/headphones-alt.svg';
import { ReactComponent as HistoryIcon } from '../assets/icons/history.svg';
import { ReactComponent as HomeIcon } from '../assets/icons/home-alt.svg';
import { ModalIds } from './contexts/modals/modals.types';
import { ReactComponent as PhoneIcon } from '../assets/icons/phone-alt.svg';
import { ReactComponent as QuestionIcon } from '../assets/icons/question-circle.svg';
import { ReactComponent as RestrictionIcon } from '../assets/icons/exclamation-octagon.svg';
import { SidebarProps } from './components/sidebar/sidebar.types';
import { ReactComponent as TelegramIcon } from '../assets/icons/telegram-alt.svg';
import { Text } from '../../../components/typography/text';
import { TooltipItem } from './components/tooltip-interactive/tooltip-item';
import { Typography } from '../../../components';
import { ReactComponent as UserChangeIcon } from '../assets/icons/user-change.svg';
import { ReactComponent as UserIcon } from '../assets/icons/user-check.svg';
import { ReactComponent as UsersIcon } from '../assets/icons/users.svg';
import { ReactComponent as WhatsAppIcon } from '../assets/icons/whatsapp.svg';
import { capitalize } from 'lodash';
import { changeLanguage } from 'i18next';
import classNames from 'classnames';
import defaultLogo from '../assets/images/default-logo.png';
import { environment } from '../../../environment';
import styles from './app.module.scss';
import { toast } from 'react-toastify';
import { useMediaQueries } from './hooks';
import { useTranslation } from 'react-i18next';

const checkRoleChangeAccess = (requestRole: UserRole, currentRole: UserRole, userRoles: ReadonlyArray<UserRole>) => {
  switch (requestRole) {
    case UserRole.admin:
      return currentRole === UserRole.admin ? false : userRoles.includes(UserRole.admin);

    case UserRole.manager:
      return currentRole === UserRole.manager
        ? false
        : userRoles.some((role) => role === UserRole.manager || role === UserRole.admin);

    case UserRole.trader:
      return currentRole === UserRole.trader
        ? false
        : userRoles.some((role) => role === UserRole.admin || role === UserRole.trader);

    default:
      return false;
  }
};

const Dashboard = lazy(() => import('./pages/dashboard'));
const Activities = lazy(() => import('./pages/activities'));
const PaymentHistory = lazy(() => import('./pages/payment-history'));
const Faq = lazy(() => import('./pages/faq'));
const Subscriptions = lazy(() => import('./pages/subscriptions'));
const AccountsHistory = lazy(() => import('./pages/accounts-history'));
const Settings = lazy(() => import('./pages/settings'));
const Strategy = lazy(() => import('./pages/strategy'));
const Asset = lazy(() => import('./pages/asset'));
const Requests = lazy(() => import('./pages/requests'));
const UndividedCapital = lazy(() => import('./pages/undivided-capital'));
const FreeAccounts = lazy(() => import('./pages/free-accounts'));
const Participants = lazy(() => import('./pages/participants'));
const ReportsHistory = lazy(() => import('./pages/reports-history'));

const ProtectedRoutes: FC = () => {
  const {
    logo,
    companyName,
    currentRole,
    establishedConnections,
    user,
    paymentFormat,
    unreadMessagesCount,
    isLoading,
    roleMigrations,
    sidebarCollapsed,
    restrictedMode,
    dispatch,
  } = useAppContext();
  const { dispatch: modalsDispatch, activeModals } = useModalsContext();
  const { t } = useTranslation('translation', { keyPrefix: 'management' });
  const { pathname } = useLocation();
  const [supportInfo, setSupportInfo] = useState<GetSupportInfoResponse>();
  const navigate = useNavigate();

  const fetchPayment = useCallback(async () => {
    try {
      if (currentRole !== UserRole.admin) return;

      const { data } = await getP4({ role: currentRole });

      if (data) {
        dispatch({ type: 'SET_PAYMENT_FORMAT', payload: data.PaymentFormat });
      }
    } catch (error) {
      toast.error('Failed to fetch payment!');
      console.error(error);
    }
  }, [dispatch, currentRole]);

  //  GETTING UNREAD MESSAGES COUNT
  const fetchData = useCallback(async () => {
    try {
      if (isLoading) return;

      if (!unreadMessagesCount && user?.email) {
        const { data } = await getUnreadMessagesCount({ email: user.email });
        data && dispatch({ type: 'SET_UNREAD_MESSAGES_COUNT', payload: data });
      }

      const { data: supportInfoData } = await getSupportInfo();

      setSupportInfo(
        supportInfoData ?? {
          Mail: 'customer@investudio.com.ua',
          Phone: '+000000000000',
          Telegram: '+000000000000',
          WhatsApp: '+000000000000',
        },
      );
    } catch (error) {
      toast.error('Failed to fetch unread message count and support info!');
      console.error(error);
    }
  }, [dispatch, isLoading, unreadMessagesCount, user?.email]);

  useEffect(() => {
    fetchData();
    fetchPayment();
  }, [fetchData, fetchPayment]);

  useEffect(() => {
    if (
      !activeModals?.some((id) => id === ModalIds.loginRequestGiveAccess) &&
      roleMigrations?.loginCandidate &&
      roleMigrations.loginCandidate.role === currentRole &&
      !pathname.includes('auth') &&
      roleMigrations.loginCandidate.email !== user?.email
    ) {
      modalsDispatch({
        type: 'SHOW_MODAL',
        payload: {
          id: ModalIds.loginRequestGiveAccess,
        },
      });
    } else if (pathname.includes('auth')) {
      modalsDispatch({ type: 'HIDE_MODAL', payload: ModalIds.loginRequestGiveAccess });
    }
  }, [currentRole, user, roleMigrations?.loginCandidate, pathname, dispatch, modalsDispatch, activeModals]);

  const links: Record<UserRole, SidebarProps['sections'][0]> = useMemo(
    () => ({
      [UserRole.admin]: [
        {
          icon: <HomeIcon />,
          label: t('sidebar.home'),
          linkTo: '/management/dashboard',
          tooltipItems: sidebarCollapsed
            ? [
                <Text variant="label4" key={0}>
                  {capitalize(t('sidebar.home'))}
                </Text>,
              ]
            : undefined,
        },
        {
          icon: <ActivityIcon />,
          label: t('sidebar.activities'),
          linkTo: '/management/activities',
          tooltipItems: sidebarCollapsed
            ? [
                <Text variant="label4" key={0}>
                  {capitalize(t('sidebar.activities'))}
                </Text>,
              ]
            : undefined,
        },
        {
          icon: paymentFormat === PaymentFormat.Subscription ? <DollarIcon /> : null,
          label: paymentFormat === PaymentFormat.Subscription ? t('sidebar.subscription') : '',
          linkTo: paymentFormat === PaymentFormat.Subscription ? '/management/subscription' : '',
          tooltipItems:
            sidebarCollapsed && paymentFormat === PaymentFormat.Subscription
              ? [
                  <Text variant="label4" key={0}>
                    {capitalize(t('sidebar.subscription'))}
                  </Text>,
                ]
              : undefined,
        },
        {
          icon: <HistoryIcon />,
          label:
            paymentFormat === PaymentFormat.Subscription
              ? t('sidebar.accounts-history')
              : t('sidebar.payments-history'),
          linkTo:
            paymentFormat === PaymentFormat.Subscription
              ? '/management/accounts-history'
              : '/management/payment-history',
          tooltipItems: sidebarCollapsed
            ? [
                <Text variant="label4" key={0}>
                  {capitalize(t('sidebar.accounts-history'))}
                </Text>,
              ]
            : undefined,
        },
        {
          icon: <QuestionIcon />,
          label: t('sidebar.faq'),
          linkTo: '/management/faq',
          tooltipItems: sidebarCollapsed
            ? [
                <Text variant="label4" key={0}>
                  {t('sidebar.faq').toUpperCase()}
                </Text>,
              ]
            : undefined,
        },
      ],
      [UserRole.manager]: [
        {
          icon: <HomeIcon />,
          label: t('sidebar.home'),
          linkTo: '/management/dashboard',
          tooltipItems: sidebarCollapsed
            ? [
                <Text variant="label4" key={0}>
                  {capitalize(t('sidebar.home'))}
                </Text>,
              ]
            : undefined,
        },
        {
          icon: <UsersIcon />,
          label: t('sidebar.members'),
          linkTo: '/management/participants',
          tooltipItems: sidebarCollapsed
            ? [
                <Text variant="label4" key={0}>
                  {capitalize(t('sidebar.members'))}
                </Text>,
              ]
            : undefined,
        },
        {
          icon: <UserIcon />,
          label: t('sidebar.requests'),
          linkTo: '/management/requests',
          tooltipItems: sidebarCollapsed
            ? [
                <Text variant="label4" key={0}>
                  {capitalize(t('sidebar.requests'))}
                </Text>,
              ]
            : undefined,
        },
        {
          icon: <DollarIcon />,
          label: t('sidebar.capital'),
          linkTo: '/management/capital',
          tooltipItems: sidebarCollapsed
            ? [
                <Text variant="label4" key={0}>
                  {capitalize(t('sidebar.capital'))}
                </Text>,
              ]
            : undefined,
        },
        {
          icon: <ClipboardIcon />,
          label: t('sidebar.accounts'),
          linkTo: '/management/free-accounts',
          tooltipItems: sidebarCollapsed
            ? [
                <Text variant="label4" key={0}>
                  {capitalize(t('sidebar.accounts'))}
                </Text>,
              ]
            : undefined,
        },
        {
          icon: <ClockIcon />,
          label: t('sidebar.reports-history'),
          linkTo: '/management/reports-history',
          tooltipItems: sidebarCollapsed
            ? [
                <Text variant="label4" key={0}>
                  {capitalize(t('sidebar.reports-history'))}
                </Text>,
              ]
            : undefined,
        },
        {
          icon: <QuestionIcon />,
          label: t('sidebar.faq'),
          linkTo: '/management/faq',
          tooltipItems: sidebarCollapsed
            ? [
                <Text variant="label4" key={0}>
                  {t('sidebar.faq').toUpperCase()}
                </Text>,
              ]
            : undefined,
        },
      ],
      [UserRole.trader]: [
        {
          icon: <HomeIcon />,
          label: t('sidebar.home'),
          linkTo: '/management/dashboard',
          tooltipItems: sidebarCollapsed
            ? [
                <Text variant="label4" key={0}>
                  {capitalize(t('sidebar.home'))}
                </Text>,
              ]
            : undefined,
        },
        {
          icon: <QuestionIcon />,
          label: t('sidebar.faq'),
          linkTo: '/management/faq',
          tooltipItems: sidebarCollapsed
            ? [
                <Text variant="label4" key={0}>
                  {t('sidebar.faq').toUpperCase()}
                </Text>,
              ]
            : undefined,
        },
      ],
    }),
    [paymentFormat, t, sidebarCollapsed],
  );

  const sideBarSections: SidebarProps['sections'] = useMemo(() => {
    const hasUnreadMessages =
      !!unreadMessagesCount?.trader || !!unreadMessagesCount?.admin || !!unreadMessagesCount?.manager;

    const rolesItems = [
      {
        icon: <UserChangeIcon />,
        label: currentRole !== UserRole.trader ? t('sidebar.trader') : '',
        disabled: !(
          currentRole &&
          user &&
          checkRoleChangeAccess(UserRole.trader, currentRole, user?.role) &&
          !restrictedMode
        ),
        onClick: async () => {
          if (user) {
            const { data } = await changeUserRole({ email: user.email, role: UserRole.trader });
            if (data && data !== 'loginRequest') {
              dispatch({ type: 'SET_CURRENT_ROLE', payload: data });
              navigate('dashboard');
            } else {
              modalsDispatch({
                type: 'SHOW_MODAL',
                payload: {
                  id: ModalIds.loginRequestChangeRole,
                  data: {
                    loginRequestChangeRole: {
                      role: UserRole.trader,
                    },
                  },
                },
              });
            }
          }
        },
        isHidden: currentRole === UserRole.trader,
        messagesCount: unreadMessagesCount?.trader,
      },
      {
        icon: <UserChangeIcon />,
        label: currentRole !== UserRole.admin ? t('sidebar.admin') : '',
        disabled: !(
          currentRole &&
          user &&
          checkRoleChangeAccess(UserRole.admin, currentRole, user?.role) &&
          !restrictedMode
        ),
        onClick: async () => {
          if (user) {
            const { data } = await changeUserRole({ email: user.email, role: UserRole.admin });
            if (data && data !== 'loginRequest') {
              dispatch({ type: 'SET_CURRENT_ROLE', payload: data });
              navigate('dashboard');
            } else {
              modalsDispatch({
                type: 'SHOW_MODAL',
                payload: {
                  id: ModalIds.loginRequestChangeRole,
                  data: {
                    loginRequestChangeRole: {
                      role: UserRole.admin,
                    },
                  },
                },
              });
            }
          }
        },
        isHidden: currentRole === UserRole.admin,
        messagesCount: unreadMessagesCount?.admin,
      },
      {
        icon: <UserChangeIcon />,
        label: currentRole !== UserRole.manager ? t('sidebar.manager') : '',
        disabled: !(
          currentRole &&
          user &&
          checkRoleChangeAccess(UserRole.manager, currentRole, user?.role) &&
          !restrictedMode
        ),
        onClick: async () => {
          if (user) {
            const { data } = await changeUserRole({ email: user.email, role: UserRole.manager });
            if (data && data !== 'loginRequest') {
              dispatch({ type: 'SET_CURRENT_ROLE', payload: data });
              navigate('dashboard');
            } else {
              modalsDispatch({
                type: 'SHOW_MODAL',
                payload: {
                  id: ModalIds.loginRequestChangeRole,
                  data: {
                    loginRequestChangeRole: {
                      role: UserRole.manager,
                    },
                  },
                },
              });
            }
          }
        },
        isHidden: currentRole === UserRole.manager,
        messagesCount: unreadMessagesCount?.manager,
      },
    ];

    const supportItems = [
      {
        icon: <EnvelopeIcon />,
        label: t('sidebar.mail'),
        href: supportInfo?.Mail ? `mailto:${supportInfo.Mail}` : '',
      },
      {
        icon: <TelegramIcon />,
        label: t('sidebar.telegram'),
        href: supportInfo?.Telegram ? `https://t.me/${supportInfo.Telegram}` : '',
        target: '_blank',
      },
      {
        icon: <WhatsAppIcon />,
        label: t('sidebar.whatsapp'),
        href: supportInfo?.WhatsApp ? `https://wa.me/${supportInfo.WhatsApp}` : '',
        target: '_blank',
      },
      {
        icon: <PhoneIcon />,
        label: t('sidebar.phone'),
        onClick: () => {
          if (!supportInfo?.Phone) return;

          window.alert(`${t('sidebar.support-phone')}\n${supportInfo.Phone}`);
        },
      },
    ];

    return [
      currentRole ? links[currentRole] : [],
      sidebarCollapsed
        ? [
            {
              icon: (
                <div
                  className={classNames(
                    styles.roleIconMessage,
                    hasUnreadMessages ? styles['roleIconMessage--indicator'] : '',
                  )}
                >
                  <UserChangeIcon />
                </div>
              ),
              label: '',
              disabled: false,
              isHidden: false,
              tooltipItems: rolesItems.map((item, index) => (
                <TooltipItem {...item} key={item.label.concat(index.toString())} />
              )),
            },
          ]
        : rolesItems,
      sidebarCollapsed
        ? [
            {
              icon: <HeadphonesIcon />,
              label: '',
              tooltipItems: supportItems.map(({ href, ...restItem }, index) => (
                <TooltipItem {...restItem} linkTo={href} key={restItem.label.concat(index.toString())} />
              )),
            },
          ]
        : [
            {
              icon: <HeadphonesIcon />,
              label: t('sidebar.support'),
              items: supportItems,
            },
          ],
    ];
  }, [
    unreadMessagesCount?.trader,
    unreadMessagesCount?.admin,
    unreadMessagesCount?.manager,
    currentRole,
    t,
    user,
    restrictedMode,
    supportInfo?.Mail,
    supportInfo?.Telegram,
    supportInfo?.WhatsApp,
    supportInfo?.Phone,
    links,
    sidebarCollapsed,
    dispatch,
    navigate,
    modalsDispatch,
  ]);

  if (!user || !localStorage.getItem('local-auth-mo')) {
    window?.location.replace(`${environment.http.authFrontend}`);
  }

  return (
    <div className={classNames(styles.mainLayout, sidebarCollapsed && styles.collapsed)}>
      <Header
        logoSrc={logo ?? defaultLogo}
        companyName={companyName}
        currentRole={currentRole}
        isBrokerConnectionEstablished={!!establishedConnections?.broker}
        isInvestudioConnectionEstablished={!!establishedConnections?.tradeVision}
        onLogout={() => dispatch({ type: 'LOGOUT' })}
      />
      <Sidebar sections={sideBarSections} />
      <div
        className={styles.content}
        style={restrictedMode ? { position: 'relative', margin: 0, paddingTop: '56px' } : undefined}
      >
        {restrictedMode && (
          <div className={styles.restriction}>
            <RestrictionIcon />
            <Typography.Text variant={'bodyText3'}>{t('restriction')}</Typography.Text>
          </div>
        )}
        <Outlet />
      </div>
    </div>
  );
};

const App: FC = () => {
  const { user, logo, currentRole, isLoading, companyName } = useAppContext();
  const { mobile, tablet } = useMediaQueries();
  const browserLanguage = window?.navigator.language?.split('-')[0];
  const userLanguage = useMemo(() => user?.preferences.language ?? undefined, [user]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      const link = document?.querySelector('link[rel="icon"]');

      if (link && logo) {
        // link.setAttribute('href', logo);
        document.title = companyName ?? '';
      }
    }
  }, [companyName, logo]);

  useEffect(() => {
    changeLanguage(userLanguage ?? browserLanguage);
  }, [browserLanguage, userLanguage]);

  if (mobile || tablet)
    return (
      <>
        <Preloader type={'overlay'} isActive={isLoading} />
        <Routes>
          <Route path={'reports/*'} element={<ReportsRouter />} />
          <Route path={'*'} element={<NoDesktop />} />
        </Routes>
      </>
    );

  return (
    <>
      <Preloader type={'overlay'} isActive={isLoading} />
      <Routes>
        <Route path={'reports/*'} element={<ReportsRouter />} />
        <Route path={'*'} element={<ProtectedRoutes />}>
          <Route
            path={'dashboard/*'}
            element={
              <Suspense fallback={<Preloader type={'overlay'} isActive />}>
                <Dashboard />
              </Suspense>
            }
          />
          <Route
            path={'activities'}
            element={
              currentRole === UserRole.admin ? (
                <Suspense fallback={<Preloader type={'overlay'} isActive />}>
                  <Activities />
                </Suspense>
              ) : (
                <Navigate to={'dashboard'} />
              )
            }
          />
          <Route
            path={'payment-history'}
            element={
              currentRole === UserRole.admin ? (
                <Suspense fallback={<Preloader type={'overlay'} isActive />}>
                  <PaymentHistory />
                </Suspense>
              ) : (
                <Navigate to={'dashboard'} />
              )
            }
          />
          <Route
            path={'faq'}
            element={
              <Suspense fallback={<Preloader type={'overlay'} isActive />}>
                <Faq />
              </Suspense>
            }
          />
          <Route
            path={'subscription'}
            element={
              currentRole === UserRole.admin ? (
                <Suspense fallback={<Preloader type={'overlay'} isActive />}>
                  <Subscriptions />
                </Suspense>
              ) : (
                <Navigate to={'dashboard'} />
              )
            }
          />
          <Route
            path={'accounts-history'}
            element={
              currentRole === UserRole.admin ? (
                <Suspense fallback={<Preloader type={'overlay'} isActive />}>
                  <AccountsHistory />
                </Suspense>
              ) : (
                <Navigate to={'dashboard'} />
              )
            }
          />
          <Route
            path={'settings/*'}
            element={
              <Suspense fallback={<Preloader type={'overlay'} isActive />}>
                <Settings />
              </Suspense>
            }
          />
          <Route
            path={'strategy'}
            element={
              currentRole === UserRole.trader ? (
                <Suspense fallback={<Preloader type={'overlay'} isActive />}>
                  <Strategy />
                </Suspense>
              ) : (
                <Navigate to={'dashboard'} />
              )
            }
          />
          <Route
            path={'asset'}
            element={
              currentRole !== UserRole.admin ? (
                <Suspense fallback={<Preloader type={'overlay'} isActive />}>
                  <Asset />
                </Suspense>
              ) : (
                <Navigate to={'dashboard'} />
              )
            }
          />
          <Route
            path={'requests'}
            element={
              currentRole === UserRole.manager ? (
                <Suspense fallback={<Preloader type={'overlay'} isActive />}>
                  <Requests />
                </Suspense>
              ) : (
                <Navigate to={'dashboard'} />
              )
            }
          />
          <Route
            path={'capital'}
            element={
              currentRole === UserRole.manager ? (
                <Suspense fallback={<Preloader type={'overlay'} isActive />}>
                  <UndividedCapital />
                </Suspense>
              ) : (
                <Navigate to={'dashboard'} />
              )
            }
          />
          <Route
            path={'free-accounts'}
            element={
              currentRole === UserRole.manager ? (
                <Suspense fallback={<Preloader type={'overlay'} isActive />}>
                  <FreeAccounts />
                </Suspense>
              ) : (
                <Navigate to={'dashboard'} />
              )
            }
          />
          <Route
            path={'information/*'}
            element={currentRole === UserRole.manager ? <InformationRouter /> : <Navigate to={'dashboard'} />}
          />
          <Route
            path={'participants'}
            element={
              currentRole === UserRole.manager ? (
                <Suspense fallback={<Preloader type={'overlay'} isActive />}>
                  <Participants />
                </Suspense>
              ) : (
                <Navigate to={'dashboard'} />
              )
            }
          />
          <Route
            path={'reports-history'}
            element={
              currentRole === UserRole.manager ? (
                <Suspense fallback={<Preloader type={'overlay'} isActive />}>
                  <ReportsHistory />
                </Suspense>
              ) : (
                <Navigate to={'dashboard'} />
              )
            }
          />

          <Route path={'*'} element={<Navigate to={'dashboard'} />} />
        </Route>
      </Routes>
    </>
  );
};

export default App;
